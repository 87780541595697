.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #800000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*green
:root{
  --highlightText: rgb(0, 255, 0);
  --importantText: rgb(226, 255, 215);
  --titleText: rgb(222, 255, 215);
  --text: rgb(180, 158, 170);
  --background: rgb(8, 35, 0);
  --transparent: rgba(255, 0, 149, 0);
  --highlightBackground: rgb(9, 75, 0);
}
*/
/*
Pink:
*/


/*yellow
:root{
  --highlightText: rgb(229, 255, 0);
  --importantText: rgb(248, 255, 215);
  --titleText: rgb(251, 255, 215);
  --text: rgb(178, 180, 158);
  --background: rgb(33, 35, 0);
  --transparent: rgba(251, 255, 0, 0);
  --highlightBackground: rgb(67, 75, 0);
}
*/
/* pink*/
:root{
  --highlightText: rgb(255, 0, 149);
  --importantText: rgb(255, 215, 239);
  --titleText: rgb(255, 215, 239);
  --text: rgb(180, 158, 170);
  --background: rgb(35, 0, 0);
  --transparent: rgba(255, 0, 149, 0);
  --highlightBackground: rgb(75, 0, 0);
}
*/
strong{
  color: var(--titleText);
}
*{
  background-color: var(--background);
  color: white;
  margin-left: auto;
  margin-right: auto;
  font-family: "Helvetica", sans-serif;
  box-sizing: border-box;
}
/*highlight */
span{
  color: var(--highlightText);
}
/*My name */
text{
  color: var(--importantText);
  
}
/* */
h1{
  color: var(--titleText);
}
h2{
  color: var(--text);
}
h3{
  color: var(--text);
}
h4{
  color: var(--text);
}

.image-holder:hover {
  opacity: 1;
  transition: opacity 1s, background 1s;
}

.image-holder {
  opacity: 0.7;
  transition: opacity 1s, background 1s;
  height: 58%;
  border-radius: 40%;
}


/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  min-width: 25em;
}

.column2 {
  float: left;
  width: 20%;
}
.column3 {
  float: left;
  width: 80%;
}

.column4 {
  float: left;
  width: 30%;
}

.column5 {
  float: left;
  width: 70%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.fade-in {
  opacity: 1;
  transition: opacity 2s, background 1s;
}


@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.realHeader {  
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 1s ease-out 0s 1 slideInFromLeft;

}

.fade-in1{
          animation: fadein 1.5s;
}

.fade-in2{
  animation: fadein 5s;
}

.fade-in3{
  animation: fadein 8s;
}
.fade-in4{
  animation: fadein 6s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

a{
  text-decoration: none;
  color: var(--titleText);
}

.btn{
  width: 95%;
  height: 3em;
  text-decoration-color: var(--highlightText);
  background-color: var(--transparent);
  display: inline-block;
  border: none;
  color: var(--text);
  text-align: center;
  padding-left: 0%;
  font-size: 100%;
  transition: all 0.2s;
  cursor: pointer;
  border-top: 0.1em dashed var(--highlightBackground);
  /*border-radius: 1em 0px 0px 0px;*/
}
button:focus{
  border: solid 0.1em;
  border-color: var(--highlightText);
  color: var(--highlightText);
  transition: 0s;
  border-radius: 0px 0px 0px 0px !important;
}
.btnSelect{
  width: 95%;
  height: 3em;
  text-decoration-color: var(--highlightText);
  background-color: var(--transparent);
  display: inline-block;
  border: solid 0.1em;
  border-color: var(--highlightText);
  color: var(--highlightText);
  text-align: center;
  transition: 0s;
  font-size: 100%;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 0px 0px 0px 0px !important;
  margin: 0;
  padding: 0;
  
}

.btnSelect:hover{
  border:  solid 0.2em;
  border-color: var(--highlightText);
  border-radius: 0px 0px 0px 0px !important;
}

button:hover{
  border:  solid 0.2em;
  border-color: var(--text);
  border-radius: 0px 0px 0px 0px !important;
}
.btnDown{
  color: var(--highlightText); 
  border:  solid 0.1em;
  border-color: transparent;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto !important;
  margin-top: auto !important;
  font-size: 1.5em;
}
.btnDown:hover{
  color:var(--titleText) !important;
  transition: 0.2s;
}

.fadeBlockIn{
  animation: fadein 3s;
}
